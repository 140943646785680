"use strict";

/**
 * Created by Thiago Lima on 16/04/2020.
 */

(function () {
  'use strict';

  var themesContainerLanguage = {
    header: {
      title: 'Defina os temas que organizam as publicações',
      themeLabel: 'Tema ',
      helper: {
        description: "Ao inserir descritores nos Temas, voc\xEA classifica os dados que foram coletados pelos grupos, possibilitando relat\xF3rios comparativos detalhados.",
        linkText: " Saiba mais como utilizar os temas.",
        link: "https://help.blip.ai/hc/pt-br/articles/18972173047063"
      }
    },
    body: {
      titleLabel: 'Título do tema',
      titleLabelWithSuggestion: 'Insira um tema ou utilize uma das sugestões',
      helper: {
        description: "Use v\xEDrgula, ponto e v\xEDrgula ou pressione \u201Center\u201D para separar os descritores. Para aprimorar sua configura\xE7\xE3o e ampliar sua coleta,",
        linkText: "veja como utilizar os operadores.",
        link: "https://help.blip.ai/hc/pt-br/articles/18955282819735"
      },
      addButton: 'Adicionar novo tema'
    },
    suggestions: {
      descriptorChangeAlert: {
        title: 'Atenção',
        text: 'Ao utilizar um tema sugerido, você irá substituir os descritores inseridos manualmente e não será possível reverter essa ação. Deseja continuar?',
        notShowCheck: 'Não mostrar mais esse alerta'
      }
    },
    error: {
      titleDuplicated: 'Os títulos não podem ser repetidos'
    },
    modal: {
      removeTheme: {
        title: 'Deseja apagar esse tema?',
        description: 'Ao apagar esse tema e salvar a pesquisa, não será possível recuperá-lo. Deseja continuar?'
      }
    }
  };
  window.moduleExport({
    themesContainerLanguage: themesContainerLanguage
  }, typeof module !== 'undefined' && module);
})();